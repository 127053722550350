import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import storage from './utils/storage'
import mixins from './mixins'
import bus from './utils/bus'
import Connect from './utils/ws'
import api from './api'
import i18n from './i18n'
import { DEV_MODE } from './utils/constants.js'
import {
	Modal,
	Row,
	Col,
	Form,
	FormModel,
	Input,
	Checkbox,
	Popconfirm,
	Button,
	Avatar,
	Upload,
	Popover,
	message,
	Pagination,
	Dropdown,
	Menu,
	ConfigProvider
} from 'ant-design-vue';
import moment from 'moment'
moment.locale('zh-CN');

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
Vue.use(VueAwesomeSwiper)
Vue.config.productionTip = false
Vue.use(Modal)
Vue.use(Row)
Vue.use(Col)
Vue.use(Form)
Vue.use(FormModel)
Vue.use(Input)
Vue.use(Checkbox)
Vue.use(Popconfirm)
Vue.use(Button)
Vue.use(Avatar)
Vue.use(Upload)
Vue.use(Popover)
Vue.use(Pagination)
Vue.use(Dropdown)
Vue.use(Menu)
Vue.use(ConfigProvider)

Vue.mixin(mixins)

const ws = new Connect()

Vue.prototype.$bus = bus
Vue.prototype.$storage = storage
Vue.prototype.$api = api
Vue.prototype.$message = message
Vue.prototype.$modal = Modal
Vue.prototype.$connect = ws

let loadingTimer = null
const loading = {
	timer: null,
	show(delay = 15000) {
		store.dispatch('setLoading', true)
		this.timer && clearTimeout(this.timer)
		this.timer = setTimeout(this.close, delay)
	},
	close() {
		store.dispatch('setLoading', false)
		this.timer && clearTimeout(this.timer)
	}
}

Vue.prototype.$loading = (delay) => {
	loading.show(delay)
}
Vue.prototype.$loadend = () => {
	loading.close()
}

const token = storage.get('token') || false
storage.set('token', token)
store.dispatch('setToken', token)

Vue.filter('dateFormat', (value, formatter) => {
	return moment(value).format(formatter)
})

Vue.filter('kindlyNumber', (val) => {
	const num = parseInt(val);
	return num > 10000 ? Math.floor(num / 100) / 100 + 'w' : num;
})


var hiddentime = 0
window.addEventListener('visibilitychange', () => {
	const activate = !document.hidden
	const now = Date.now() / 1000
	if (!activate) {
		hiddentime = now
	} else if (hiddentime > 0 && now - hiddentime > 60) {
		ws.reInit()
		if (hiddentime > 300) {
			bus.$emit('refresh')
		}
		hiddentime = 0
	}
})

if (!DEV_MODE) {
	window['console']['log'] = function() {};
	window['console']['info'] = function() {};
}

new Vue({
	i18n,
	router,
	store,
	render: h => h(App)
}).$mount('#app')
