import Vue from 'vue'
import Vuex from 'vuex'
import api from '../api'
Vue.use(Vuex)

const TYPES = {
	'TOKEN': 'TOKEN',
	'USER_INFO': 'USER_INFO',
	'MEMBER_INFO': 'MEMBER_INFO',
	'APPOINTMENTS': 'APPOINTMENTS',
	'LOADING': 'LOADING',
	'DOWNLOAD_URL': 'DOWNLOAD_URL'
}
export default new Vuex.Store({
	state: {
		token: '',
		minfo: {},
		appointments: [],

		loading: false,


		iosUrl: "",
		androidUrl: ""
	},
	mutations: {
		[TYPES.TOKEN](state, token) {
			state.token = token
		},
		[TYPES.MEMBER_INFO](state, minfo) {
			state.minfo = minfo
		},
		[TYPES.APPOINTMENTS](state, appointments) {
			state.appointments = appointments
		},
		[TYPES.LOADING](state, loading) {
			state.loading = loading
		},
		[TYPES.DOWNLOAD_URL](state, {
			iosUrl,
			androidUrl
		}) {
			state.iosUrl = iosUrl
			state.androidUrl = androidUrl
		}
	},
	actions: {
		async setToken(context, token = false) {
			context.commit(TYPES.TOKEN, token)
			if (token) {
				context.dispatch('getMemberInfo')
			}
		},
		async getMemberInfo(context) {
			const response = await api.get('/get_user')
			if (response && response.code == 200) {
				context.commit(TYPES.MEMBER_INFO, response.data)
				return response.data
			}
			return Promise.reject(false)
		},
		async setMemberInfo(context, minfo = {}) {
			context.commit(TYPES.MEMBER_INFO, minfo)
		},
		async syncMemberInfo(context) {
			if (context.state.minfo.uid) {
				return context.state.minfo
			}
			return context.dispatch('getMemberInfo')
		},
		async getAppointments(context) {
			const response = await api.get('/web/appointment/list')
			if (response) {
				const appointments = []
				Object.keys(response.data).forEach(date => {
					appointments.push({
						date,
						lists: response.data[date]
					})
				})
				context.commit(TYPES.APPOINTMENTS, appointments)
			}
		},
		async setLoading(context, show) {
			context.commit(TYPES.LOADING, show)
		},
		async getDownloadUrls(context) {
			const response = await api.get('/web/download')
			if (response.code == 200) {
				const { android_url, ios_url } = response.data
				const data = {
					iosUrl: ios_url,
					androidUrl: android_url
				}
				if (location.hostname == 'seo01.0ldy.com') {
					data.androidUrl = 'https://cdn.x3zy.com/upload/app/android/NlL6d_1.0.2_4_202410301607.apk'
				} else if (location.hostname == 'seo02.0ldy.com') {
					data.androidUrl = 'https://cdn.x3zy.com/upload/app/android/203nl_1.0.2_4_202410301607.apk'
				} else if (location.hostname == 'seo03.0ldy.com') {
					data.androidUrl = 'https://cdn.x3zy.com/upload/app/android/SEO_3_1.0.2_10_202411200955.apk'
				} else if (location.hostname == 'seo04.0ldy.com') {
					data.androidUrl = 'https://cdn.x3zy.com/upload/app/android/SEO_4_1.0.2_10_202411200957.apk'
				} else if (location.hostname == 'seo05.0ldy.com') {
					data.androidUrl = 'https://cdn.x3zy.com/upload/app/android/SEO-5_1.0.2_10_202411281149.apk'
				} else if (location.hostname == 'seo06.0ldy.com') {
					data.androidUrl = 'https://cdn.x3zy.com/upload/app/android/SEO-6_1.0.2_10_202411281150.apk'
				} else if (location.hostname == 'ttk1.0ldy.com') {
					data.androidUrl = 'https://cdn.x3zy.com/upload/app/android/tiktok_new_1.0.2_2_202409251615.apk'
				} else if (location.hostname == 'ttk.0ldy.com') {
					data.androidUrl = 'https://cdn.x3zy.com/upload/app/android/006_Sports-Live_TTK.apk'
				} else if (location.hostname == 'ytb.0ldy.com') {
					data.androidUrl = 'https://cdn.x3zy.com/upload/app/android/006_Sports-Live_YTB_1.0.2_2_202408281050.apk'
				} else if (['gg.0ldy.com', 'gg1.0ldy.com', 'ggtg.0ldy.com', 'ggtg1.0ldy.com'].includes(location.hostname)) {
					data.androidUrl = 'https://cdn.x3zy.com/upload/app/android/006_Sports-Live_GG_1.0.2_2_202408281049.apk'
				} else if (location.hostname == 'fb2.0ldy.com') {
					data.androidUrl = 'https://cdn.x3zy.com/upload/app/android/YCwII_1.0.2_4_202410301618.apk'
				} else if (location.hostname == 'fb3.0ldy.com') {
					data.androidUrl = 'https://cdn.x3zy.com/upload/app/android/RI51s_1.0.2_4_202410301617.apk'
				} else if (location.hostname == 'fb4.0ldy.com') {
					data.androidUrl = 'https://cdn.x3zy.com/upload/app/android/fb4_1111_1.0.2_10_202411131517.apk'
				} else if (location.hostname == 'fb5.0ldy.com') {
					data.androidUrl = 'https://cdn.x3zy.com/upload/app/android/fb5_1111_1.0.2_10_202411131517.apk'
				} else if (location.hostname == 'fb6.0ldy.com') {
					data.androidUrl = 'https://cdn.x3zy.com/upload/app/android/fb6_1111_1.0.2_10_202411131518.apk'
				} else if (location.hostname == 'fb7.0ldy.com') {
					data.androidUrl = 'https://cdn.x3zy.com/upload/app/android/fb7_1111_1.0.2_10_202411131518.apk'
				} else if (location.hostname == 'fb8.0ldy.com') {
					data.androidUrl = 'https://cdn.x3zy.com/upload/app/android/fb8_1111_1.0.2_10_202411131519.apk'
				} else if (location.hostname == 'fb01.0ldy.com') {
					data.androidUrl = 'https://cdn.x3zy.com/upload/app/android/fb01_1119_1.0.2_10_202411200959.apk'
				} else if (location.hostname == 'fb02.0ldy.com') {
					data.androidUrl = 'https://cdn.x3zy.com/upload/app/android/fb02_1119_1.0.2_10_202411201000.apk'
				} else if (location.hostname == 'seofb.0ldy.com') {
					data.androidUrl = 'https://cdn.x3zy.com/upload/app/android/SEO-FB_1.0.2_10_202411131519.apk'
				} else if (location.hostname == 'seoyt.0ldy.com') {
					data.androidUrl = 'https://cdn.x3zy.com/upload/app/android/SEO-YT_1.0.2_10_202411131520.apk'
				} else if (['gg.115xx.com', 'sta1.qi4q.com'].includes(location.hostname)) {
					data.androidUrl = 'https://cdn.x3zy.com/upload/app/android/sta1_1.0.2_10_202502191104.apk'
				} else if (location.hostname == 'sta2.qi4q.com') {
					data.androidUrl = 'https://cdn.x3zy.com/upload/app/android/sta2_1.0.2_10_202502191106.apk'
				} else if (location.hostname == 'sta3.qi4q.com') {
					data.androidUrl = 'https://cdn.x3zy.com/upload/app/android/sta3_1.0.2_10_202502191107.apk'
				} else if (location.hostname == 'fb.0ldy.com') {
					if (options.p0) {
						data.androidUrl = `https://app.adjust.com/${options.p0}?campaign=${options.p1}%20%28${options.p2}%29&adgroup=${options.p3}%20%28${options.p4}%29&creative=${options.p5}%20%28${options.p6}%29&redirect=https%3A%2F%2Fcdn.x3zy.com%2Fupload%2Fapp%2Fandroid%2F006_Sports-Live_FB_1.0.2_2_202408281051.apk`
						const fbpid = getFbPid()
						if (fbpid && options.fbclid) {
							data.androidUrl += `&fbclid=${options.fbclid}&fbpid=${fbpid}`
						}
					} else {
						data.androidUrl = 'https://cdn.x3zy.com/upload/app/android/006_Sports-Live_FB_1.0.2_2_202408281051.apk'
					}
				}
				context.commit(TYPES.DOWNLOAD_URL, data)
			}
			return response.data
		}
	}
})
